<template>
  <div>
    <button  v-if="status === 'рассчитан'" class="btn btn-accent" @click="callback(id)">{{ text }}</button>
  </div>
</template>

<script>

import router from "@/router";
import store from "@/store";

export default {
  props: {
    id: Number,
    text: String,
    status: String,
    type: String,
  },
  setup(props) {
    const goToAnalytics = (id) => {
      router.push( { path:'/analytics/reference', query: { id: id }})
    }

    const saveReport = (id) => {
      store.dispatch("analytics/getReferenceReport", id).then(()=>{
        router.push(`/profiles/report/${id}`);
      })
    }

    const callback = props.type === 'analytics' ? goToAnalytics : saveReport;

    return {
      callback,
    }
  }
};
</script>
