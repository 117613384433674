<template>
  <button v-if="getRight('deleteReferences')" @click="callback(id)" class="btn">
    <i class="fal fa-trash"></i>
  </button>
</template>

<script>
import useCheckRights from '@/mixins/useCheckRights';
import store from "@/store";

export default {
  props: {
    id: Number,
    type: String,
  },
  setup(props) {
    const {getRight} = useCheckRights();

    const deleteItem = (id) => {
      store.dispatch("analytics/deleteReferenceProfile", id)
    };

    const deleteItemExcel = (id) => {
      store.dispatch("references/deleteReferenceExcel", id)
    };

    const callback = props.type === 'excel' ? deleteItemExcel : deleteItem;

    return {
      getRight,
      callback
    };
  }
}
</script>