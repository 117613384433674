<template>
  <Popper placement="top-start" arrow class="products-popper">
    <button class="products-popper-btn display--flex align-item-center">
      {{ freeCount }} <i class="fal fa-angle-down"></i>
    </button>
    <template #content>
      <ul class="quota_list">
        <li class="display--flex justify-content-between popper-info">Доступно<span>{{ freeCount }}</span></li>
        <li class="display--flex justify-content-between popper-info">Выдано<span>{{ issuedCount }}</span></li>
      </ul>
    </template>
  </Popper>
</template>

<script>
import {ref} from "@vue/reactivity";
import Popper from "vue3-popper";
export default {
  components: {Popper},
  props: {
    value: Number,
    countIssued: Number,
  },
  setup(props) {
    const freeCount = ref(null)
    const issuedCount = ref(null)
    freeCount.value = props.value ?? null;
    issuedCount.value = props.countIssued ?? 0;

    return {
      freeCount,
      issuedCount,
    }
  }
}
</script>


<style scoped lang="scss">

.popper-info {
  padding: 10px 9px;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.fa-angle-down {
  margin-left: 10px;
}

.products-popper-btn:hover {
  color: var(--hover-color)
}

.popper {
  width: 200px;
}
</style>