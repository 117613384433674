<template>
  <button
    @click="
      store.commit('ui/showCoursePopup', {
        show: true,
        courses: courses,
        trajectories: trajectories
      })
    "
    class="course-btn"
    v-if="courses.length || trajectories.length"
  >
    <i class="fal fa-clipboard-check"></i>
  </button>
  <button
    @click="showNotification('Нет назначенных курсов или тренажеров')"
    class="course-btn blocked"
    v-else
  >
    <i class="fal fa-clipboard"></i>
  </button>
</template>

<script>
import { notify } from "@kyvg/vue3-notification";
import { useStore } from "vuex";
import courses from "@/feature/home/courses.vue";
export default {
  name: "class-renderer",
  props: {
    classes: {
      type: Array,
      default: () => [[], []],
    }
  },
  setup(props) {
    const store = useStore();
    const courses = typeof props.classes[0] === 'object' ? Object.values(props.classes[0]) : props.classes[0];
    const trajectories = typeof props.classes[1] === 'object' ? Object.values(props.classes[1]) : props.classes[1];

    const showNotification = (text, type = "warning") => {
      notify({
        title: text,
        type,
      });
    };

    return { store, showNotification, courses, trajectories };
  },
};
</script>

<style lang="scss" scoped>
.course-btn {
  width: 25px;
  height: 45px;
  margin: auto;
  display: block;
  i {
    font-size: 24px !important;
    color: var(--main-color) !important;
  }

  &:not(.blocked):hover i {
    color: var(--light-color) !important;
  }

  &.blocked {
    cursor: auto;
    i {
      color: grey !important;
    }
  }
}
</style>
