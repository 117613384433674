<template>
  <button @click="callback(id)" class="btn btn-accent">
    {{ text }}
  </button>
</template>

<script>
export default {
  props: {
    text: String,
    id: Number,
    callback: Function,
  },
}
</script>