<script>
import CustomCheckbox from "@/components/Forms/Fields/CustomCheckbox.vue";
import ImageRenderer from "@/components/Tables/CellRenderers/ImageRenderer.vue";
import GroupsRenderer from "@/components/Tables/CellRenderers/UserGroupsRenderer.vue";
import EditRenderer from "@/components/Tables/CellRenderers/EditRenderer.vue";
import AuthorizationRenderer from "@/components/Tables/CellRenderers/AuthorizationRenderer.vue";
import TextLinkRenderer from "@/components/Tables/CellRenderers/TextLinkRenderer.vue";
import {ref} from "@vue/runtime-core";
import ColorRenderer from "@/components/Tables/CellRenderers/ColorRenderer.vue";
import PassingLinkRenderer from "@/components/Tables/CellRenderers/PassingLinkRenderer.vue";
import SaveReport from "@/components/Tables/CellRenderers/SaveReport.vue";
import DeleteReference from "@/components/Tables/CellRenderers/DeleteReference.vue";
import ShortTextRenderer from "@/components/Tables/CellRenderers/ShortTextRenderer.vue";
import ButtonRenderer from "@/components/Tables/CellRenderers/ButtonRenderer.vue";
import ChangeNumberRenderer from "@/components/Tables/CellRenderers/ChangeNumberRenderer.vue";
import DeleteRenderer from "@/components/Tables/CellRenderers/DeleteRenderer.vue";
import EntityOrderRenderer from "@/components/Tables/CellRenderers/EntityOrderRenderer.vue";
import {watchEffect} from "vue";
import ProductRenderer from "@/components/Tables/CellRenderers/ProductsRenderer.vue";
import ButtonRepurchase from "@/components/Tables/CellRenderers/ButtonRepurchase.vue";
import OrderApprove from "@/components/Tables/CellRenderers/OrderApprove.vue";
import ExcelStatusTextRenderer from "@/components/Tables/CellRenderers/ExcelStatusTextRenderer.vue";
import NewPartnerRenderer from "@/components/Tables/CellRenderers/NewPartnerRenderer.vue";
import TextListRenderer from "@/components/Tables/CellRenderers/TextListRenderer.vue";
import StatusRenderer from "@/components/Tables/CellRenderers/StatusRenderer.vue";
import ClassRenderer from "@/components/Tables/CellRenderers/ClassRenderer.vue";
import ActionsRenderer from "@/components/Tables/CellRenderers/ActionsRenderer.vue";
import ButtonPass from "@/components/Tables/CellRenderers/ButtonPass.vue";
import FreeQuotaAction from "@/components/Tables/CellRenderers/FreeQuotaAction.vue";

export default {
  name: "BaseTable",
  components: {
    FreeQuotaAction,
    ButtonPass,
    ActionsRenderer,
    ClassRenderer,
    StatusRenderer,
    TextListRenderer,
    NewPartnerRenderer,
    ExcelStatusTextRenderer,
    OrderApprove,
    ButtonRepurchase,
    ProductRenderer,
    EntityOrderRenderer,
    DeleteRenderer,
    ChangeNumberRenderer,
    ButtonRenderer,
    ShortTextRenderer,
    DeleteReference,
    SaveReport,
    PassingLinkRenderer,
    ColorRenderer,
    TextLinkRenderer,
    AuthorizationRenderer,
    EditRenderer,
    GroupsRenderer,
    ImageRenderer,
    CustomCheckbox
  },
  props: {
    columns: Object,
    rows: Object,
    checkedList: Array,
    rowSelect: Function,
    cellAction: Function,
    actionOptions: Array,
    hiddenColumns: Array,
  },
  emits: ['sort', 'row-select'],
  setup(props) {
    const sortValue = ref('');
    const direction = ref('');
    const rowStyle = {
      1: 'green',
      2: 'yellow',
      3: 'red'
    };

    const sortColumn = (field) => {
      if (!sortValue.value || sortValue.value !== field) {
        sortValue.value = field;
        direction.value = 'up';

      } else {
        if (direction.value === 'up') {
          direction.value = 'down';
        } else {
          sortValue.value = '';
          direction.value = '';
        }
      }
    }

    const allSelected = ref(false);

    const checkAll = () => {
      allSelected.value = !allSelected.value;
      props.rows.forEach(row => {
        props.rowSelect(row.id, allSelected.value)
      });
    }

    watchEffect(() => {
      const currentItems = props.rows.map(row => row.id);
      allSelected.value = (props.checkedList && props.checkedList.length && (currentItems.every((item) => props.checkedList.includes(item))));
    });

    return {
      rowStyle,
      sortColumn,
      direction,
      sortValue,
      allSelected,
      checkAll
    }
  }
}
</script>

<template>
  <div class="base_table_wrapper">
    <table class="base_table">
      <tr class="table_head_row">
        <template v-for="(column, index) in columns" v-bind:key="index">

          <th v-if="column.field === 'check' && column.checkAll" class="table_header header_checkbox">
            <CustomCheckbox
                @change="checkAll"
                :checked=allSelected
            />
          </th>

          <template v-else>
            <th v-if="!hiddenColumns || !hiddenColumns.length || (hiddenColumns.length && !hiddenColumns.includes(column.field))"
                class="table_header" :class="[{'sortable': column.sortable}, {'name': column.field === 'name'}]"
                @click="() => {
                if (column.sortable) {
                  sortColumn(column.field);
                  $emit('sort', {sortValue, direction})
                }
              }"
            >
              {{ column.headerName }}
              <span v-if="column.sortable" class="arrow" :class="[{ [direction]: column.field === sortValue}]">
              </span>
            </th>
          </template>

        </template>
      </tr>
      <template v-for="(row, index) in rows" class="table_row" :key="row.id ? row.id + Math.random() : index + Math.random()">
        <tr class="table_main"
            :class="{[rowStyle[row.group]]: row.group}">
          <template v-for="(column, index) in columns" v-bind:key="index">
            <td v-if="!hiddenColumns || !hiddenColumns.length || (hiddenColumns.length && !hiddenColumns.includes(column.field))"
                @click="() => column.actionCallback ? column.actionCallback(row.id) : null" :class="{'action': column.actionCallback}">
              <div class="cell_wrapper">

                <CustomCheckbox v-if="column.field === 'check'" @change="() => rowSelect(row.id)" :data-id=row.id
                                :checked="checkedList && checkedList.includes(row.id)"/>

                <span
                    v-if="column.field !== 'eiToken' && column.field !== 'photo' && column.field !== 'image' && column.headerName"
                    class="mobile_header">
                  {{ column.headerName }}
                </span>

                <div v-if="column.field === 'photo' || column.field === 'image'" class="photo_cell">
                  <ImageRenderer :image="row.photo ?? row.image"/>
                </div>

                <div v-else-if="column.field === 'groups'" class="group_cell">
                  <GroupsRenderer :groups="row.groups"/>
                </div>

                <div v-else-if="column.link" class="link_renderer">
                  <TextLinkRenderer :id="row[column.linkId] ?? row.id" :name="row[column.field]" :source="column.dest"
                                    :end="column.res" :value="row[column.field]"/>
                </div>

                <div v-else-if="column.field === 'entity'" class="entity_renderer">
                  <EntityOrderRenderer :value="row.entity"/>
                </div>

                <div v-else-if="column.field === 'short'" class="short">
                  <ShortTextRenderer :string="row[column.originField]"/>
                </div>

                <div v-else-if="column.field === 'eiToken'" class="auth_button_cell">
                  <AuthorizationRenderer :id="row.id" :user_role="row.role"/>
                </div>

                <div v-else-if="column.field === 'products'" class="product_renderer">
                  <ProductRenderer :products="row.products" :entities="row.entities"/>
                </div>

                <div v-else-if="column.field === 'save_report'" class="report_renderer">
                  <SaveReport :id="row.id" :text="column.text" :status="row.calculated" :type="column.type"/>
                </div>

                <div v-else-if="column.field === 'button'" class="button_renderer">
                  <ButtonRenderer :id="row.id" :text="column.text" :callback="column.callback"/>
                </div>

                <div v-else-if="column.field === 'partner_renderer'" class="partner_renderer">
                  <NewPartnerRenderer :value="row.new"/>
                </div>

                <div v-else-if="column.field === 'color'" class="color_renderer">
                  <ColorRenderer :color="row[column.field]"/>
                </div>

                <div v-else-if="column.field === 'code'" class="code_renderer">
                  <PassingLinkRenderer :value="row[column.field]" :status="row.passing_status" :type="row.report_type"
                                       :allowed="row.allowed"/>
                </div>

                <div v-else-if="column.field === 'excel_status'" class="status_excel_renderer">
                  <ExcelStatusTextRenderer :value="row.status" :successColor="column.successColor"/>
                </div>

                <div v-else-if="column.field === 'order_user_id'" class="order_user_button">
                  <ButtonRepurchase :order="row"/>
                </div>

                <div v-else-if="column.field === 'order_status'" class="order_approve">
                  <OrderApprove :order="row" :value="row.status"/>
                </div>

                <div v-else-if="column.field === 'change_count'" class="count_change_renderer">
                  <ChangeNumberRenderer :callback="column.callback" :name="row.name"/>
                </div>

                <div v-else-if="column.field === 'delete_item'" class="delete_item_renderer">
                  <DeleteRenderer :callback="column.callback" :name="row.name"/>
                </div>

                <div v-else-if="column.field === 'delete_reference'" class="reference_delete">
                  <DeleteReference :id="row.id" :type="'reference'"/>
                </div>

                <div v-else-if="column.field === 'delete_reference_excel'" class="reference_delete">
                  <DeleteReference :id="row.id" :type="'excel'"/>
                </div>

                <div v-else-if="column.field === 'edit'" class="edit_cell">
                  <div v-if="column.action" class="action_renderer">
                    <ActionsRenderer :groupId="column.groupId" :testOptions="actionOptions"
                                     :allowViewReport="row.allowViewReport"
                                     :report="row.report" :code="row.code" :status="row.status" :link="row.reportLink"
                                     :staffId="row.id"
                    />
                  </div>
                  <EditRenderer :value="row.id" :dest="column.dest" :res="column.res" :group="column.group"/>
                </div>

                <div v-else-if="column.field === 'passing_count'" class="count_renderer">
                  <TextListRenderer :value="row.passing_count"/>
                </div>

                <div v-else-if="column.field === 'status'" class="status_renderer">
                  <StatusRenderer :status="row.status" :finished_tests="row.finished_tests" :isTest="column.isTest" :expiration_date="row.expiration_date ?? null"/>
                </div>

                <div v-else-if="column.field === 'class'" class="class_renderer">
                  <ClassRenderer :classes="row.class"/>
                </div>

                <div v-else-if="column.field === 'buttonPass'" class="button_pass_renderer">
                  <ButtonPass :id="row.id" :code="row.code" :status="row.status" :coursePassing="row.coursePassing" :is_course_active="row.is_course_active"/>
                </div>

                <div v-else-if="column.field === 'countFree'" class="free_quota_renderer">
                  <FreeQuotaAction :value="row[column.field]" :issuedCount="row.issuedCount" />
                </div>

                <div v-else-if="column.field === 'review'" class="review">
                  <p>{{ row.text }}</p>
                </div>

                <div v-else-if="column.field === 'user'" class="name">
                  <p>{{ row.user }}</p>
                </div>

                <p v-else>
                  {{ row[column.field] ?? '' }}
                </p>
              </div>
            </td>
          </template>
        </tr>
      </template>
    </table>
  </div>
</template>

<style scoped lang="scss">

td, th {
  padding: 20px 15px;
}

tr:nth-child(even) {
  background-color: #f5f5f5;
}

tr {
  border-bottom: 1px solid var(--cream-color);
}

.table_main {
  font-weight: 400;
  color: var(--common-text-color);
}

.table_main:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

tr {
  font-size: 15px;
}

.base_table_wrapper {
  width: 100%;
  min-height: 400px;
  overflow: auto;
}

.base_table {
  width: 100%;
  border-collapse: collapse;
  cursor: default;
}

.report_renderer {
  width: 150px;
}

.table_header {
  min-width: 140px;
  min-height: 60px;
  position: relative;
  padding: 15px 25px 15px;
  text-align: center;
  font-size: 15px;
  color: var(--common-text-color);
}

.header_checkbox .form-group {
  justify-content: center;
}

.employee_table {
  width: 100%;
}

.cell_wrapper {
  display: flex;
  min-width: 50px;
  justify-content: center;
}

.cell_wrapper p,
.link_renderer,
.entity_renderer,
.short {
  text-align: center;
}

.mobile_header {
  display: none;
}

.check {
  margin-top: 15px;
  margin-left: 15px;
  width: 50px;
  height: 50px;
}

.auth_button_cell,
.group_cell {
  width: 160px;
}

.arrow {
  position: absolute;
  top: 1.2em;
  right: 5px;
  width: 15px;
  height: 10px;
  display: block;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  font-size: 15px;
}

.arrow::before {
  content: "\f883"
}

.arrow.up::before {
  content: "\f176"
}

.arrow.down::before {
  content: "\f175"
}

.name {
  min-width: 200px;
}

.yellow {
  color: #e2b500;
}

.green {
  color: #00e287;
}

.red {
  color: red;
}

.name a {
  color: inherit;
}

.action {
  cursor: pointer;
}

.action p:hover {
  color: var(--hover-color)
}

.sortable {
  cursor: pointer;
}

.edit_cell {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 15px;
}

.photo_cell {
  margin: auto;
}

.class_renderer {
  margin: auto;
}

.action_renderer {
  margin-left: auto;
}

.entity_renderer,
.product_renderer {
  margin: auto;
}

.order_user_button {
  min-width: 150px;
}

.status_excel_renderer {
  padding-left: 10px;
}

.partner_renderer {
  width: 200px;
}

.free_quota_renderer {
  margin: auto;
}

.review {
  padding: 0 5px;
  width: 270px;
  height: 150px;
  overflow: auto;
}

.code_renderer {
  margin: auto;
}

.group_cell {
  text-align: left;
}

@media (max-width: 800px) {
  tr {
    display: flex;
    flex-direction: column;
  }

  td {
    padding: 10px;
  }

  td p {
    word-break: break-word;
  }

  .table_header {
    display: none;
  }

  .mobile_header {
    display: block;
    max-width: 230px;
    margin-right: 10px;
    font-weight: bold;
    color: var(--common-text-color);
  }

  .cell_wrapper {
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    row-gap: 5px;
  }

  .cell_wrapper p {
    text-align: left;
  }

  .group_cell {
    text-align: right;
  }

  .class_renderer,
  .code_renderer,
  .entity_renderer,
  .product_renderer,
  .free_quota_renderer,
  .button_renderer,
  .count_change_renderer {
    margin: 0 0 0 auto;
  }

  .report_renderer {
    margin-left: auto;
  }

  .base_table_wrapper {
    margin-top: 25px;
  }

  .auth_button_cell,
  .photo_cell {
    text-align: center;
    margin: auto;
  }

  .edit_cell {
    margin-right: 0;
  }

  .partner_renderer {
    text-align: center;
    margin: auto;
  }

}
</style>
