<template>
  <button @click="callback(name)" class="btn">
    <i class="fal fa-trash"></i>
  </button>
</template>

<script>
export default {
  props: {
    callback: Function,
    name: String,
  },
}
</script>
