<template>
  <span :class="[[status], {[successColor]: status === 'success'}]">{{ value }}</span>
</template>

<script>
export default {
  props: {
    value: String,
    successColor: String,
  },
  setup(props) {
    let status = (props.value === 'Успех' || props.value === 'успешно') ? 'success' : 'fail';
    return {
      status
    }
  }
}
</script>

<style>
.success {
  color: #2cc185;
}

.fail {
  color: #e30c0c;
}

.black {
  color: black;
}
</style>