<template>
  <div>
    <div class="short-text">{{ text }}</div>
  </div>
</template>

<script>

export default {
  props: {
    string: String,
  },
  setup(props) {
    let text = props.string ?? '';

    if (props.string.length > 80) {
      text = props.string.substring(0, 77) + "...";
    }

    return {
      text,
    }
  }
}
</script>

<style lang="scss" scoped>
.short-text {
  font-size: 14px;
}
</style>