<template>
  <router-link v-if="getRight('superadmin')" @click="repurchase" to="/orders/create" class="btn btn-accent">
    Купить повторно
  </router-link>
</template>
<script>
import { useStore } from "vuex"; 
import useCheckRights from "@/mixins/useCheckRights";
export default {
  props: {
    order: Object,
  },
  data(props) {
    const store = useStore(),
    { getRight } = useCheckRights();

    const repurchase = () => {
      store.commit('relations/setCurrentOrder', props.order)
    }
    return {
      repurchase,
      getRight,
    }
  },
}
</script>