<template>
  <div v-if="source && end && value">
    <router-link
      :to="`/${source}/${id}/${end}`"
      >{{ value.length > 0 ? value : name }}</router-link
    >
  </div>
  <div v-else-if="source && value">
    <router-link :to="`/${source}/${id}`">{{
        value
      }}</router-link>
  </div>
  <div v-else>
    <router-link :to="`/${source}/${id}/edit`">{{
        name
      }}</router-link>
  </div>
</template>

<script>
export default {
  props: {
    params: Object,
    value: {
      required: false,
    },
    id: {
      type: Number,
      required: false
    },
    source: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    end: {
      type: String,
      required: false,
    },
    group: {
      required: false,
    }
  },
};
</script>

<style lang="scss" scoped>
a {
  width: 100%;
  display: block;
  &:hover {
    color: var(--hover-color);
  }
}
</style>