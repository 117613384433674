<template>
  <div class="order-approve">
    <span>{{ value }}</span>
    <Popper v-if="(value === 'Новый' || value === 'Отправлен') && getRight('approveOrders')"
            content="Подтвердить" arrow hover >
      <button
        @click="approveOrder"
        class="btn btn-w btn-sm"
      >
        <i class="fal fa-check"></i>
      </button>
    </Popper>
  </div>
</template>

<script>
import { useStore } from "vuex";
import useCheckRights from "@/mixins/useCheckRights";
export default {
  name: "order-approve",
  props: {
    order: Object,
    value: String,
  },
  setup(props) {
    const store = useStore(),
    { getRight } = useCheckRights();

    const approveOrder = () => {
        const order = props.order;
        order.status = "new"
        store.dispatch("relations/paidOrder", order)
    };

    return {
      approveOrder,
      getRight,
    };
  },
};
</script>

<style lang="scss" scoped>
.order-approve {
  display: flex;
  align-items: center;
  span {
    margin-right: 15px;
  }
  button {
    margin: 2px;
    padding: 4px 6px;
    i {
      font-size: 14px;
    }
  }
}

i {
  margin-left: 0;
}

@media (max-width: 768px) {
  .order-approve {
    justify-content: flex-end;
  }
}
</style>


