<template>
  <Popper placement="top-start" arrow class="action-popper" v-if="status">
    <button
      v-if="
        !(
          (status === 'Тест завершен' ||
            reportLink) &&
          report === 'Для исследования'
        )
      "
      class="action-btn"
    >
      <i class="fal fa-list-ul"></i>
    </button>
    <template #content>
      <ul v-if="status !== 'Тест завершен'">
        <div v-if="true">
          <giving-tests-mini
            :testOptionsCustom="testOptions"
            :groupId="groupId"
            :isSingle="true"
            :checkedList="[staffId]"
          />
        </div>
        <li
          v-if="
            status === 'Тест не пройден' ||
            status === 'В процессе прохождения'
          "
        >
          <button @click="remindTest">Напомнить пройти тест</button>
        </li>
        <li
          v-if="
            status === 'Тест не пройден' ||
            status === 'В процессе прохождения'
          "
        >
          <button @click="takePassing">Забрать тест</button>
        </li>
        <li
          v-if="
            status === 'Тест не пройден' ||
            status === 'В процессе прохождения'
          "
        >
          <button
            @click="copyToClipboard(`${url}/passing/${code}`)"
          >
            Копировать ссылку на тестирование
          </button>
        </li>
        <div v-if="isPreloader" class="btn-preloader">
          <mini-preloader />
        </div>
      </ul>
      <ul
        v-if="
          status === 'Тест завершен' ||
          reportLink
        "
      >
        <li @click="openReport">
          <button>Открыть отчет</button>
        </li>
        <li v-if="!allowViewReport" @click="sendReport">
          <button>Отправить отчет</button>
        </li>
        <div v-if="isPreloader" class="btn-preloader">
          <mini-preloader />
        </div>
      </ul>
    </template>
  </Popper>
</template>

<script>
import { notify } from "@kyvg/vue3-notification";
import { useStore } from "vuex";
import GivingTestsMini from "@/feature/group-control/popups/GivingTestsMini.vue";
import { ref } from "@vue/reactivity";
import MiniPreloader from "@/components/Technical/MiniPreloader.vue";

export default {
  components: {
    GivingTestsMini,
    MiniPreloader,
  },
  props: {
    staffId: Number,
    groupId: Number,
    report: String,
    status: String,
    testOptions: Array,
    code: String,
    allowViewReport: Boolean,
    link: String,
  },
  setup(props) {
    const store = useStore();
    const url = process.env.VUE_APP_FRONT,
      reportLink = ref(props.link ?? null),
      isPreloader = ref(false);

    const remindTest = () => {
      store.dispatch("test/remindTest", props.code);
    };

    const takePassing = () => {
      isPreloader.value = true;
      store
        .dispatch("group/takeStaffPassings", {
          id: props.groupId,
          staffIds: props.staffId,
        })
        .finally(() => {
          isPreloader.value = false;
        });
    };

    const getReport = async () => {
      isPreloader.value = true;
      return store
        .dispatch("test/getReportLink", props.code)
        .then(({ data }) => {
          reportLink.value = data;
        })
        .finally(() => (isPreloader.value = false));
    };

    const openReport = async () => {
      await getReport();
      window.open(reportLink.value, "_blank");
    };

    const sendReport = () => {
      if (!props.allowViewReport) {
        store.dispatch("group/allowToViewReport", {
          id: props.groupId,
          staffIds: props.staffId,
        });
      }
    };

    const copyReport = async () => {
      await getReport();
      copyToClipboard(reportLink.value);
    };

    const copyToClipboard = (link) => {
      navigator.clipboard
        .writeText(link)
        .then(() => {
          notify({
            title: "Ссылка скопирована",
            type: "success",
          });
        })
        .catch((error) => {
          notify({
            title: "Произошла ошибка",
            text: error,
            type: "error",
          });
        });
    };

    return {
      remindTest,
      takePassing,
      openReport,
      copyReport,
      copyToClipboard,
      url,
      sendReport,
      isPreloader,
      reportLink,
    };
  },
};
</script>
<style lang="scss">
.action-popper li {
  padding: 0;
}
</style>
<style lang="scss" scoped>
.action-btn {
  height: 50px;
  width: 50px;
}
ul {
  position: relative;

  button {
    width: 200px;
    height: 100%;
    text-align: start;
    padding: 10px;
  }
}
</style>